import React from 'react';
import NewsCard from './newsCard';
import Title from '../Title';
import { useStaticQuery, graphql } from 'gatsby';
import styles from '../../css/news.module.css';

const getNews = graphql`
	query {
		nieuws: allContentfulNieuws(sort: { fields: datum, order: DESC }) {
			edges {
				node {
					id: contentful_id
					titel
					slug
					datum(formatString: "DD-MM-YYYY")
					afbeelding {
						fluid {
							...GatsbyContentfulFluid
						}
					}
				}
			}
		}
	}
`;
const NewsList = () => {
	const { nieuws } = useStaticQuery(getNews);
	return (
		<section className={styles.news}>
			<Title title='Het laatste' subtitle='nieuws' />
			<div className={styles.center}>
				{nieuws.edges.map(({ node }) => {
					return <NewsCard key={node.id} news={node} />;
				})}
			</div>
		</section>
	);
};

export default NewsList;
