import React from 'react';
import styles from '../../css/news-card.module.css';
import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

const NewsCard = ({ news }) => {
	const { slug, titel, afbeelding, datum } = news;
	return (
		<article className={styles.news}>
			<div className={styles.imgContainer}>
				<Image fluid={afbeelding.fluid} className={styles.img} alt='nieuws item' />
				<AniLink fade className={styles.link} to={`/nieuws/${slug}`}>
					Lees verder
				</AniLink>
				<h6 className={styles.date}>{datum}</h6>
			</div>
			<div className={styles.footer}>
				<h4>{titel}</h4>
			</div>
		</article>
	);
};

export default NewsCard;
